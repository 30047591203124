.txn-result-wrapper {
  margin: 10px auto;
  border: solid 1px #ccc;
  min-width: 400px;
  .txn-header {
    text-align: center;
    padding: 10px 0;
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
  }
  .txn-body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    span {
      margin: 0 20px;
    }
  }
}
